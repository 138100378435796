import { render, staticRenderFns } from "./examPaperTemplate.vue?vue&type=template&id=b4343444&scoped=true"
import script from "./examPaperTemplate.vue?vue&type=script&lang=js"
export * from "./examPaperTemplate.vue?vue&type=script&lang=js"
import style0 from "./examPaperTemplate.vue?vue&type=style&index=0&id=b4343444&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4343444",
  null
  
)

export default component.exports