<template>
    <el-scrollbar class="paper-wrapper" style="height: 100%" :native="false">
        <div class="paper-container">
            <div class="paper-title">{{paperTitle}}</div>
            <div class="paper-content">
                <exam-paper-template :data.sync="paperContent"></exam-paper-template>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import _ from "underscore";
    import common from "../../../utils/common";
    import examPaperTemplate from "./examPaperTemplate";
    export default {
        name: "examPaperPreView",
        props:['paper_id'],
        created() {
            this.examId = this.paper_id;
            this.getExamInfo();
        },
        components: {
            examPaperTemplate
        },
        data() {
            return {
                examId: null,
                paperTitle: '',
                paperContent: {},
                multipleOptions: []
            }
        },
        methods: {
            translateNum(index) {
                return common.getNumStr(index);
            },
            getExamInfo() {
                this.$thttp.axiosGetBy(this.$api.findTestPaper, { id: this.examId }, (res) => {
                    if (res.code === 200) {
                        this.paperTitle = res.data.paper_name;
                        let paper_content = JSON.parse(res.data.paper_content);
                        for(let key in paper_content) {
                            if (paper_content[key].length) {
                                if (key === 'multiple') {
                                    _.map(paper_content[key], (item) => {
                                        item.checkOptions = [];
                                    });
                                }
                                this.$set(this.paperContent, `${key}`, paper_content[key]);
                            }
                        }
                    }
                })

            }
        }
    }
</script>
<style scoped lang="scss">
    .paper-wrapper {
        width: 100%;
        height: 100%;
        /*background: #fff;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .paper-container {
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            padding: 20px;
            box-sizing: border-box;
            .paper-title {
                text-align: center;
                color: #409eff;
                font-size: 24px;
                font-weight: 500;
            }
            .paper-content {
                margin-top: 20px;

            }
        }
    }
</style>