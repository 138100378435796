<template>
    <div>
        <div class="question-type-item" v-for="(questionItem, questionIndex) in data">
            <div class="question-type-title">
                <span class="title">{{getQtype(questionIndex)}}</span>
                <span class="subtitle">(共{{questionItem | countArr}}题， 共{{questionItem | countScore}}分)</span>
            </div>
            <div class="subject-item" v-for="(subjectItem, subjectIndex) in questionItem">
                <div class="subject-title">
                    <span>{{subjectIndex + 1}}.</span>
                    <span class="score">({{subjectItem.score}}分)</span>
                    <span class="title" v-html="subjectItem.topic.title"></span>
                </div>
                <el-radio-group v-model="singleOption[subjectItem.id]" class="subject-answer" v-if="subjectItem.topic_type === 1 || subjectItem.topic_type === 3">
                    <el-radio :label="optionIndex" class="answer-item" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                        {{optionIndex + 1 | formatNumber}}、{{optionItem.name}}
                    </el-radio>
                </el-radio-group>
                <el-checkbox-group v-model="subjectItem.checkOptions" class="subject-answer" v-if="subjectItem.topic_type === 2">
                    <el-checkbox :label="optionIndex"  class="answer-item" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                        {{optionIndex + 1 | formatNumber}}、{{optionItem.name}}
                    </el-checkbox>
                </el-checkbox-group>
                <div class="subject-answer" v-if="subjectItem.topic_type === 4">
                    <div class="textarea-box" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                        <span>({{optionIndex + 1}})&nbsp;&nbsp;</span>
                        <el-input v-model="fillBlankOption[subjectItem.id]" placeholder="请输入答案" style="width: 400px"></el-input>
                    </div>
                </div>
                <div class="subject-answer" v-if="subjectItem.topic_type === 5">
                    <div class="textarea-box">
                        <el-input type="textarea" :rows="5" class="textarea-box" placeholder="请输入答案" style="width: 400px" v-model="askAnswerOption[subjectItem.id]"></el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'underscore';
    import functions from "../../../utils/common";
    export default {
        name: "examPaperTemplate",
        props: {
            data: Object,
        },
        watch: {
            property: {
                immediate: true,
                handler() {
                }
            },
        },
        data() {
            return {
                singleOption: [],
                fillBlankOption: [],
                askAnswerOption: []
            }
        },
        mounted() {
            // console.log('data', this.data)
        },
        filters: {
            countArr(value) {
                return value.length;
            },
            countScore(value) {
                let total = 0;
                for(let i=0; i<value.length; i++) {
                    total += parseInt(value[i].score);
                }
                return total;
            },
            formatNumber(value) {
                return functions.formatAlph(value);
            },
        },
        methods: {
            getQtype(value) {
                let indexStr = '';
                switch (value) {
                    case 'single':
                        indexStr = functions.translateNumberByObject(this.data, 'single');
                        return `${indexStr}、单选题`;
                        break;
                    case 'multiple':
                        indexStr = functions.translateNumberByObject(this.data, 'multiple');
                        return `${indexStr}、多选题`;
                        break;
                    case 'judge':
                        indexStr = functions.translateNumberByObject(this.data, 'judge');
                        return `${indexStr}、判断题`;
                        break;
                    case 'fillBlank':
                        indexStr = functions.translateNumberByObject(this.data, 'fillBlank');
                        return `${indexStr}、填空题`;
                        break;
                    case 'askAnswer':
                        indexStr = functions.translateNumberByObject(this.data, 'askAnswer');
                        return `${indexStr}、问答题`;
                        break;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .question-type-item {
        margin-bottom: 20px;
        .question-type-title {
            .title {
                color: #333;
                font-size: 18px;
                font-weight: 500;
            }
            .subtitle {
                font-size: 16px;
                color: #666;
            }
        }
        .subject-item {
            margin-top: 10px;
            padding-bottom: 20px;
            .subject-title {
                color: #333;
                font-size: 16px;
                font-weight: 500;
                .title {
                    ::v-deep p {
                        margin: 0;
                        display: inline;
                    }
                }
                .score {
                    color: #666;
                    opacity: 0.7;
                    margin: 0 10px;
                }
            }
            .subject-answer {
                display: block;
                margin-top: 15px;
                padding-left: 20px;
                .answer-item {
                    margin-top: 6px;
                    display: flex;
                    ::v-deep .el-radio__label {
                        width: 1%;
                        flex: 1;
                        white-space: normal;
                        line-height: 1.3;
                    }
                }
                .textarea-box {
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>